import Keycloak, {KeycloakInitOptions, KeycloakLoginOptions, KeycloakLogoutOptions} from 'keycloak-js';
import {ConfigService} from "../ConfigService";
import {Subject} from "rxjs";
import {frameContent} from '../components/FrameContent';

class UserContext {

    private keycloak: Keycloak.KeycloakInstance;
    public onAuthRefreshUpdate = new Subject<void>();

    private static instance: UserContext;

    public static getInstance(): UserContext {
        if (!UserContext.instance) {
            UserContext.instance = new UserContext();
        }

        return UserContext.instance;
    }

    public init(): Promise<boolean> {
        this.keycloak = new Keycloak(ConfigService.config.keycloak);

        this.initKeycloakEventlisteners();

        return new Promise<any>((resolve, reject) => {
            let options: KeycloakInitOptions = {
                onLoad: 'check-sso',
                checkLoginIframe: true,
                silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                checkLoginIframeInterval: 5,
                enableLogging: true,
                pkceMethod: 'S256'
            };

            this.keycloak.init(options).then(() => {
                resolve(this.keycloak.authenticated);
            }).catch((err) => {
                console.error('[FRAME] Keycloak init error', err);
                reject(err);
            });
        });
    }

    private initKeycloakEventlisteners(){
        this.keycloak.onAuthLogout = () => {
            this.onAuthRefreshUpdate.next();
        };
        this.keycloak.onActionUpdate = (a) => {
            console.log('[FRAME] ActionUpdate', a);
        }
        this.keycloak.onAuthError = (e) => {
            console.log('[FRAME] Auth error', e);
        }
        this.keycloak.onReady = (b) => {
            console.log('[FRAME] keycloak.onReady', b);
        }

        this.keycloak.onAuthRefreshSuccess = () => {
            console.log('[FRAME] AuthRefreshSuccess');
            this.onAuthRefreshUpdate.next();
        }
        this.keycloak.onAuthRefreshError = () => {
            console.log('[FRAME] AuthRefreshError');
            this.onAuthRefreshUpdate.next();
        }

        this.keycloak.onTokenExpired = () => {
            //Uus token saab 30s aega enne kui aegunuks loetakse
            this.keycloak.updateToken(30).then((refreshed: boolean) => {
                if (refreshed) {
                    console.log('[FRAME] Token was successfully refreshed');
                } else {
                    console.log('[FRAME] Token is still valid');
                }
            }).catch(() => {
                console.error('[FRAME] Failed to refresh the token, or the session has expired');
                this.onAuthRefreshUpdate.next();
            });
        }
    }

    public login(options?: KeycloakLoginOptions): Promise<any> {
        return this.keycloak.login(options);
    }

    public logout(options?: KeycloakLogoutOptions): Promise<any> {
        if(!options){
            const activeSiteUrl = frameContent.getActiveSiteUrl();
            if(activeSiteUrl){
                options = {redirectUri: activeSiteUrl}
            }
        }

        return this.keycloak.logout(options);
    }

    public getToken() {
        return this.keycloak.token;
    }

    public getTokenParsed(): any {
        return this.keycloak.tokenParsed;
    }

    public isAuthenticated() {
        return this.keycloak.authenticated;
    }
}

export const userContext = UserContext.getInstance();
