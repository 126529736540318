import processTpl, {genLinkReplaceSymbol, genScriptReplaceSymbol} from './process-tpl';
import {
    defaultGetPublicPath,
    getGlobalProp,
    getInlineCode,
    noteGlobalProps,
    readResAsString,
    requestIdleCallback
} from './utils';
import {throwNonBlockingError} from '../utils';

export interface ImportResult {
    template: string;
    assetPublicPath: string;

    execScripts<T>(sandbox?: object, strictGlobal?: boolean, execScriptsHooks?: ExecScriptsHooks): Promise<T>;

    getExternalScripts(): Promise<string[]>;

    getExternalStyleSheets(): Promise<string[]>;
}

export type ImportEntryOpts = {
    fetch?: typeof window.fetch | { fn?: typeof window.fetch, autoDecodeResponse?: boolean }
    errorCallback?: (err: any) => void
}

export type ExecScriptsHooks = {
    beforeExec?: (code: string, script: string) => string | void;
    afterExec?: (code: string, script: string) => void;
}

export interface ManifestSource {
    styles?: string[],
    scripts?: string[],
    html?: string
}

export type Entry = string | ManifestSource;

const styleCache: any = {};
const scriptCache: any = {};
const embedHTMLCache: any = {};
if (!window.fetch) {
    throw new Error('[import-html-entry] Here is no "fetch" on the window env, you need to polyfill it');
}
const defaultFetch = window.fetch.bind(window);

const isInlineCode = (code: string) => code.startsWith('<');

const supportsUserTiming =
    typeof performance !== 'undefined' &&
    typeof performance.mark === 'function' &&
    typeof performance.clearMarks === 'function' &&
    typeof performance.measure === 'function' &&
    typeof performance.clearMeasures === 'function';


export function importEntry(entry: Entry, opts: ImportEntryOpts = {}): Promise<ImportResult> {
    // console.log('Import entry', entry, opts);

    if (!entry) {
        // console.log('No entry. Opts', opts);
        throw new SyntaxError('entry should not be empty!');
    }

    if (typeof entry === 'string') {
        return importHTML(entry, opts);
    }

    if ((Array.isArray(entry.scripts) || Array.isArray(entry.styles))) {
        return importEntrySourceEntry(entry, opts);
    }

    throw new SyntaxError('entry should be Url or scripts or styles should be array!');
}

function importEntrySourceEntry(entry: ManifestSource, opts: ImportEntryOpts = {}): Promise<ImportResult> {

    const {fetch = defaultFetch} = opts;
    const {scripts = [], styles = [], html = ''} = entry;

    const getHTMLWithStylePlaceholder = (tpl: any) => styles.reduceRight((htmlV, styleSrc) => `${genLinkReplaceSymbol(styleSrc)}${htmlV}`, tpl);
    const getHTMLWithScriptPlaceholder = (tpl: any) => scripts.reduce((htmlV, scriptSrc) => `${htmlV}${genScriptReplaceSymbol(scriptSrc)}`, tpl);

    const template: string = getHTMLWithScriptPlaceholder(getHTMLWithStylePlaceholder(html));

    return getEmbedHTML(template, styles, fetch)
        .then((embedHTML: string) => {
            return {
                template: embedHTML,
                assetPublicPath: defaultGetPublicPath(entry),
                getExternalScripts: () => getExternalScripts(scripts, fetch, null),
                getExternalStyleSheets: () => getExternalStyleSheets(styles, fetch),
                execScripts: (sandbox: any, strictGlobal: boolean, execScriptsHooks: ExecScriptsHooks = {}) => {
                    if (!scripts.length) {
                        return Promise.resolve();
                    }

                    return execScripts(scripts[scripts.length - 1], scripts, sandbox, {
                        fetch,
                        strictGlobal,
                        beforeExec: execScriptsHooks.beforeExec,
                        afterExec: execScriptsHooks.afterExec
                    });
                }
            } as ImportResult;
        });
}

function importHTML(url: string, opts: ImportEntryOpts): Promise<ImportResult> {
    let fetch = defaultFetch;
    let autoDecodeResponse = false;
    if (opts.fetch) {
        // fetch is a funciton
        if (typeof opts.fetch === 'function') {
            fetch = opts.fetch;
        } else { // configuration
            fetch = opts.fetch.fn || defaultFetch;
            autoDecodeResponse = !!opts.fetch.autoDecodeResponse;
        }
    }

    if (embedHTMLCache[url]) {
        return embedHTMLCache[url];
    }

    embedHTMLCache[url] =
        fetch(url)
            .then((response: Response) => {
                if (response.status >= 400) {
                    return Promise.reject('EmbedHTMLCache fetch failed: ' + url);
                }
                return readResAsString(response, autoDecodeResponse);
            })
            .then((html: string) => {

                if (html == undefined) {
                    return;
                }

                const assetPublicPath: string = defaultGetPublicPath(url);
                const {template, scripts, entry, styles} = processTpl(html, assetPublicPath);

                return getEmbedHTML(template, styles, fetch)
                    .then((embedHTML: string) => {
                        return {
                            template: embedHTML,
                            assetPublicPath,
                            getExternalScripts: () => getExternalScripts(scripts, fetch, undefined),
                            getExternalStyleSheets: () => getExternalStyleSheets(styles, fetch),
                            execScripts: (sandbox: any, strictGlobal: boolean, execScriptsHooks: ExecScriptsHooks = {}) => {
                                if (!scripts.length) {
                                    return Promise.resolve();
                                }

                                return execScripts(entry, scripts, sandbox, {
                                    fetch,
                                    strictGlobal,
                                    beforeExec: execScriptsHooks.beforeExec,
                                    afterExec: execScriptsHooks.afterExec
                                });
                            }
                        } as ImportResult;
                    });
            }).catch((err: any) => {
            // console.log(err)
            return Promise.reject('Failed to fetch application from url: ' + url);
        });

    return embedHTMLCache[url];
}

/** convert external css link to inline style for performance optimization*/
function getEmbedHTML(embedHTML: string, styles: string[], fetch: any): Promise<string> {
    return getExternalStyleSheets(styles, fetch)
        .then(styleSheets => {
            embedHTML = styles.reduce((html, styleSrc, i) => {
                html = html.replace(genLinkReplaceSymbol(styleSrc), `<style>/* ${styleSrc} */${styleSheets[i]}</style>`);
                return html;
            }, embedHTML);
            return embedHTML;
        });
}

/**
 * Future improvements: To consistent with browser behavior, we should only provide callback way to invoke success and error event
 * @param entry
 * @param scripts
 * @param proxy
 * @param opts
 * @returns {Promise<unknown>}
 */
function execScripts(entry: Entry, scripts: string[], proxy = window, opts: any = {}): Promise<void> {

    const {
        fetch = defaultFetch,
        strictGlobal = false,
        success,
        error = () => {
            // This is intentional
        },
        beforeExec = () => {
            // This is intentional
        },
        afterExec = () => {
            // This is intentional
        }
    } = opts;

    return getExternalScripts(scripts, fetch, error)
        .then((scriptsText) => {

            const geval = (scriptSrc: string, inlineScript: string) => {
                const rawCode = beforeExec(inlineScript, scriptSrc) || inlineScript;
                const code: string = getExecutableScript(scriptSrc, rawCode, proxy, strictGlobal);
                (0, eval)(code);
                afterExec(inlineScript, scriptSrc);
            };

            function exec(scriptSrc: string, inlineScript: any, resolve: any): void {

                // console.log('Exec ----', scriptSrc);
                const markName = `Evaluating script ${scriptSrc}`;
                const measureName = `Evaluating Time Consuming: ${scriptSrc}`;

                if (process.env.NODE_ENV === 'development' && supportsUserTiming) {
                    performance.mark(markName);
                }

                if (scriptSrc === entry) {
                    // console.log('ENTRY --', scriptSrc,  entry);
                    noteGlobalProps(strictGlobal ? proxy : window);

                    try {
                        // bind window.proxy to change `this` reference in script
                        geval(scriptSrc, inlineScript);
                        let gIndex: any = getGlobalProp(strictGlobal ? proxy : window);
                        // console.log('PROXY --',gIndex, proxy);
                        const exports = proxy[gIndex] || {};
                        resolve(exports);
                    } catch (e) {
                        // entry error must be thrown to make the promise settled
                        console.error(`[import-html-entry]: error occurs while executing entry script ${scriptSrc}`);
                        throw e;
                    }
                } else {
                    if (typeof inlineScript === 'string') {
                        try {
                            // bind window.proxy to change `this` reference in script
                            geval(scriptSrc, inlineScript);
                        } catch (e) {
                            // consistent with browser behavior, any independent script evaluation error should not block the others
                            throwNonBlockingError(e, `[import-html-entry]: error occurs while executing normal script ${scriptSrc}`);
                        }
                    } else {
                        // external script marked with async
                        inlineScript.async && inlineScript?.content
                            .then((downloadedScriptText: any) => geval(inlineScript.src, downloadedScriptText))
                            .catch((e: any) => {
                                throwNonBlockingError(e, `[import-html-entry]: error occurs while executing async script ${inlineScript.src}`);
                            });
                    }
                }

                if (process.env.NODE_ENV === 'development' && supportsUserTiming) {
                    performance.measure(measureName, markName);
                    performance.clearMarks(markName);
                    performance.clearMeasures(measureName);
                }
            }

            const schedule = (i: number, resolvePromise: any) => {
                if (i < scripts.length) {
                    const scriptSrc = scripts[i];
                    const inlineScript = scriptsText[i];

                    // console.log('Exec ', scriptSrc);
                    exec(scriptSrc, inlineScript, resolvePromise);
                    // resolve the promise while the last script executed and entry not provided
                    if (!entry && i === scripts.length - 1) {
                        resolvePromise();
                    } else {
                        schedule(i + 1, resolvePromise);
                    }
                }
            }

            return new Promise((resolve) => schedule(0, success || resolve));
        });
}

export function getExternalScripts(scripts: string[], fetch: any, errorCallback: () => {}): Promise<string[]> {

    const fetchScript = (scriptUrl: string) => scriptCache[scriptUrl] ||
        (scriptCache[scriptUrl] = fetch(scriptUrl).then((response: any) => {
            // usually browser treats 4xx and 5xx response of script loading as an error and will fire a script error event
            // https://stackoverflow.com/questions/5625420/what-http-headers-responses-trigger-the-onerror-handler-on-a-script-tag/5625603
            if (response.status >= 400) {
                if (errorCallback) {
                    errorCallback();
                }
                throw new Error(`${scriptUrl} load failed with status ${response.status}`);
            }

            return response.text();
        }));

    return Promise.all(scripts.map((script) => {

            if (typeof script === 'string') {
                if (isInlineCode(script)) {
                    return getInlineCode(script);
                } else {
                    return fetchScript(script);
                }
            } else {
                // use idle time to load async script
                const {src, async} = script;
                if (async) {
                    return {
                        src,
                        async: true,
                        content: new Promise((resolve, reject) => {
                                return requestIdleCallback(() => {
                                        return fetchScript(src).then(resolve, reject)
                                    }
                                )
                            }
                        )
                    };
                }

                return fetchScript(src);
            }
        }
    ));
}

function getExecutableScript(scriptSrc: string, scriptText: string, proxy: Window, strictGlobal: boolean) {
    const sourceUrl = isInlineCode(scriptSrc) ? '' : `//# sourceURL=${scriptSrc}\n`;
    const globalWindow = (0, eval)('window');
    globalWindow.proxy = proxy;
    return strictGlobal
        ? `;(function(window, self, globalThis){with(window){;${scriptText}\n${sourceUrl}}}).bind(window.proxy)(window.proxy, window.proxy, window.proxy);`
        : `;(function(window, self, globalThis){;${scriptText}\n${sourceUrl}}).bind(window.proxy)(window.proxy, window.proxy, window.proxy);`;
}

export function getExternalStyleSheets(styles: string[], fetch = defaultFetch): Promise<any[]> {
    return Promise.all(styles.map((styleLink) => {
            if (isInlineCode(styleLink)) {
                return getInlineCode(styleLink);
            } else {
                return styleCache[styleLink] ||
                    (styleCache[styleLink] = fetch(styleLink).then((response: any) => response.text()));
            }
        }
    ));
}
