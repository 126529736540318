import {Matomo} from '../../model/interfaces';

class Head {
    private static instance: Head

    public static getInstance(): Head {
        if (!Head.instance) {
            Head.instance = new Head()
        }
        return Head.instance
    }

    public setTitle(title: string): void {
        document.title = title;
    }

    public initMatomo(config: Matomo) {

        if (!config || !config.siteId || !config.url) {
            console.error('Tracker config not provided');
            return;
        }

        // @ts-ignore
        const paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        paq.push(['trackPageView']);
        paq.push(['enableLinkTracking']);
        const u = config.url; // "https://piwik.smit.ee";
        paq.push(['setTrackerUrl', u + '/matomo.php']);
        paq.push(['setSiteId', config.siteId]);
        const d = document;
        const g = d.createElement('script');
        const s = d.getElementsByTagName('script')[0];
        g.async = true;
        g.type = 'text/javascript';
        g.src = u + '/matomo.js';
        s.parentNode?.insertBefore(g, s);
    }
}

export const head = Head.getInstance()
