import {isFunction, snakeCase} from 'lodash';

export function throwNonBlockingError(error: any, msg: any): void {
    setTimeout(() => {
        console.error(msg);
        throw error;
    });
}

export function toArray<T>(array: T | T[]): T[] {
    return Array.isArray(array) ? array : [array];
}

export function getDefaultTplWrapper(id: string, name: string, tpl: string) {
    return `<div id="${getWrapperId(id)}" data-name="${name}">${tpl}</div>`;
}

function getWrapperId(id: string) {
    return `__frame_microapp_wrapper_for_${snakeCase(id)}__`;
}

export function validateExportLifecycle(exports: any) {
    const {bootstrap, mount, unmount} = exports ?? {};
    return  isFunction(bootstrap) &&
            isFunction(mount) &&
            isFunction(unmount);
}

export class Deferred<T> {
    promise: Promise<T>;

    resolve!: (value: T | PromiseLike<T>) => void;

    reject!: (reason?: any) => void;

    constructor() {
        this.promise = new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });
    }
}

const supportsUserTiming =
    typeof performance !== 'undefined' &&
    typeof performance.mark === 'function' &&
    typeof performance.clearMarks === 'function' &&
    typeof performance.measure === 'function' &&
    typeof performance.clearMeasures === 'function' &&
    typeof performance.getEntriesByName === 'function';

export function performanceGetEntriesByName(markName: string, type?: string) {
    let marks = null;
    if (supportsUserTiming) {
        marks = performance.getEntriesByName(markName, type);
    }
    return marks;
}

export function performanceMark(markName: string) {
    if (supportsUserTiming) {
        performance.mark(markName);
    }
}

export function performanceMeasure(measureName: string, markName: string) {
    if (supportsUserTiming && performance.getEntriesByName(markName, 'mark').length) {
        performance.measure(measureName, markName);
        performance.clearMarks(markName);
        performance.clearMeasures(measureName);
    }
}

export function getContainer(container: string | HTMLElement): HTMLElement | null {
    return typeof container === 'string' ? document.querySelector(container) : container;
}
