import {concat, mergeWith} from 'lodash';
import type {FrameworkLifeCycles, ObjectType} from '../interfaces';

const rawPublicPath = window.__INJECTED_PUBLIC_PATH_BY_FRAME__;

const getEngineFlagAddon = (global: Window): FrameworkLifeCycles<any> => {
    return {
        async beforeLoad() {
            global.__POWERED_BY_FRAME__ = true;
        },

        async beforeMount() {
            global.__POWERED_BY_FRAME__ = true;
        },

        async beforeUnmount() {
            delete global.__POWERED_BY_FRAME__;
        },
    };
}

const getRuntimePublicPathAddOn = (global: Window, publicPath = '/'): FrameworkLifeCycles<any> => {
    let hasMountedOnce = false;

    return {
        async beforeLoad() {
            global.__INJECTED_PUBLIC_PATH_BY_FRAME__ = publicPath;
        },

        async beforeMount() {
            if (hasMountedOnce) {
                global.__INJECTED_PUBLIC_PATH_BY_FRAME__ = publicPath;
            }
        },

        async beforeUnmount() {
            if (rawPublicPath === undefined) {
                delete global.__INJECTED_PUBLIC_PATH_BY_FRAME__;
            } else {
                global.__INJECTED_PUBLIC_PATH_BY_FRAME__ = rawPublicPath;
            }
            hasMountedOnce = true;
        },
    };
}



export default function getAddOns<T extends ObjectType>(global: Window, publicPath: string): FrameworkLifeCycles<T> {
    return mergeWith(
        {},
        getEngineFlagAddon(global),
        getRuntimePublicPathAddOn(global, publicPath),
        (v1, v2) =>
            concat(v1 ?? [], v2 ?? []),
    );
}
