class NotificationBar {

    private static sessionKey: string = 'ohp-notification-bar-closed';

    private static instance: NotificationBar;

    private frameNotificationElement: HTMLVeeraNotificationElement;

    private constructor() {
        this.frameNotificationElement = document.getElementById('ohp-notification-bar') as HTMLVeeraNotificationElement;
        this.frameNotificationElement.addEventListener('close', () => {
            this.hideNotificationElement();
            sessionStorage.setItem(NotificationBar.sessionKey, this.frameNotificationElement.text);
        });
        return this;
    }

    public static getInstance(): NotificationBar {
        if (!NotificationBar.instance) {
            NotificationBar.instance = new NotificationBar();
        }
        return NotificationBar.instance;
    }

    public init(): NotificationBar {
        return this;
    }

    public frameNotification(message?: string) {
        if (!message || sessionStorage.getItem(NotificationBar.sessionKey) == message) {
            return;
        }
        this.frameNotificationElement.text = message;
        this.showNotificationElement();
    }

    private hideNotificationElement() {
        this.frameNotificationElement.setAttribute('style', 'display: none;');
    }

    private showNotificationElement() {
        this.frameNotificationElement.setAttribute('style', 'display: inherit;');
    }
}

export const notificationBar = NotificationBar.getInstance();
