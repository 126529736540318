import {Site} from "../model/interfaces";

export class MaintenanceApp {

    public static register(site: Site) {

        let global = window as any;

        global[site.id] = {
            bootstrap: () => {
                return Promise.resolve();
            },
            mount: (props: any) => {
                let appContent = document.createElement('veera-notification-page');
                appContent.heading = 'Toimuvad hooldustööd';
                appContent.textMessage = site.maintenanceMessage;
                appContent.type = 'maintenance';
                appContent.show = true;
                appContent.innerHTML = site.maintenanceMessage;
                appContent.addEventListener("homeButtonClicked", () => {
                    history.pushState(null, '/ohp', '/ohp')
                });

                document.getElementById(props.siteId).appendChild(appContent);
                return Promise.resolve();
            },
            unmount: () => {
                return Promise.resolve();
            }
        };
    }
}
