
const isIE11 = typeof navigator !== 'undefined' && navigator.userAgent.indexOf('Trident') !== -1;

function shouldSkipProperty(global: any, p: any) {
    if (!global.hasOwnProperty(p) || !isNaN(p) && p < global.length) {
        return true;
    }

    if (isIE11) {
        try {
            return global[p] && typeof window !== 'undefined' && global[p].parent === window;
        } catch (err) {
            return true;
        }
    } else {
        return false;
    }
}

// safari unpredictably lists some new globals first or second in object order
let firstGlobalProp: any;
let secondGlobalProp: any;
let lastGlobalProp: any;

export function getGlobalProp(global: any) {
    //console.log('Get global prop', global);
    let cnt = 0;
    let lastProp;
    let hasIframe = false;

    for (let p in global) {
        if (shouldSkipProperty(global, p)) {
            continue;
        }

        //iframe, window iframe，first second
        for (let i = 0; i < window.frames.length && !hasIframe; i++) {
            const frame = window.frames[i];
            if (frame === global[p]) {
                hasIframe = true;
                //console.log('Has iframe');
                break;
            }
        }

        if (!hasIframe && (cnt === 0 && p !== firstGlobalProp || cnt === 1 && p !== secondGlobalProp)){
            //console.log('Why I return p?', p);
            return p;
        }

        cnt++;

        //TODO Ei tea, mida see kood siin teeb aga e mõeldakse rakenduse olemasolu
        // e tegemist peaks olema rakendusega, millel on lifecycle funktsioon
        if(global[p] && global[p].hasOwnProperty('bootstrap')) {
            //console.log('Has bootstrap', global[p]);
            lastProp = p;
        }
    }

    if (lastProp !== lastGlobalProp) {
        return lastProp;
    }

    return null;
}

export function noteGlobalProps(global: any): any {
    // alternatively Object.keys(global).pop()
    // but this may be faster (pending benchmarks)
    firstGlobalProp = secondGlobalProp = undefined;
    for (let p in global) {
        if (shouldSkipProperty(global, p)) {
            continue;
        }

        if (!firstGlobalProp) {
            firstGlobalProp = p;
        } else if (!secondGlobalProp) {
            secondGlobalProp = p;
        }

        //Last property with lifecycle
        if(global[p] && global[p].hasOwnProperty('bootstrap')) {
            lastGlobalProp = p;
        }
    }

    //console.log('Noted ', lastGlobalProp);

    return lastGlobalProp;
}

export function getInlineCode(match: any) {
    const start = match.indexOf('>') + 1;
    const end = match.lastIndexOf('<');
    return match.substring(start, end);
}

export function defaultGetPublicPath(entry: any) {
    if (typeof entry === 'object') {
        return '/';
    }
    try {
        const { origin, pathname } = new URL(entry.startsWith('//') ? `${location.protocol}${entry}` : entry, location.href);
        const paths = pathname.split('/');
        paths.pop();
        return `${origin}${paths.join('/')}/`;
    } catch (e) {
        console.warn(e);
        return '';
    }
}

// Detect whether browser supports `<script type=module>` or not
export function isModuleScriptSupported() {
    const scriptElement = document.createElement('script');
    return 'noModule' in scriptElement;
}

// RIC and shim for browsers setTimeout() without it
export const requestIdleCallback =
    window.requestIdleCallback ||
    function requestIdleCallback(cb: any) {
        const start = Date.now();
        return setTimeout(() => {
            cb({
                didTimeout: false,
                timeRemaining() {
                    return Math.max(0, 50 - (Date.now() - start));
                },
            });
        }, 1);
    };

export function readResAsString(response: Response, autoDetectCharset: any) {

    if (!autoDetectCharset) {
        return response.text();
    }

    if (!response.headers) {
        return response.text();
    }

    const contentType = response.headers.get('Content-Type');
    if (!contentType) {
        return response.text();
    }

    // Content-Type: text/html; charset=utf-8
    // Content-Type: multipart/form-data; boundary=something
    let charset = 'utf-8';
    const parts = contentType.split(';');
    if (parts.length === 2) {
        const [, value] = parts[1].split('=');
        const encoding = value && value.trim();
        if (encoding) {
            charset = encoding;
        }
    }

    if (charset.toUpperCase() === 'UTF-8') {
        return response.text();
    }

    return response.blob()
        .then((file: any) => new Promise((resolve, reject) => {
            const reader = new window.FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsText(file, charset);
        }));
}
