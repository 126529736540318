export class ErrorPage {

    public static render(message?: string | undefined) {

        let template = '<div><h3>Raami laadimisel tekkis viga !</h3></div>';
        if (message) {
            template += '<p>' + message + '</p>';
        }
        document.getElementById('ohp-subapp-viewport').innerHTML = template;
    }
}
