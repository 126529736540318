import {ConfigService} from '../ConfigService';
import {AntirobotKeyResponse} from '../model/interfaces';

/*
* Doc:
* https://antirobot.smit.ee/docs/#/installation
* Swagger:
* https://antirobot-service.smit.dev/swagger-ui/index.html?configUrl=/v3/api-docs/swagger-config#/anti-robot-controller/verifyAntiRobot
*/

class Antirobot {

    private static instance: Antirobot;
    private uid: number;

    private antirobotInstance: any;

    public static getInstance(): Antirobot {
        if (!Antirobot.instance) {
            Antirobot.instance = new Antirobot()

        }
        return Antirobot.instance
    }

    constructor() {
        this.uid = new Date().getTime();
    }

    public init() {
        if (typeof (window as any).antirobot === 'undefined') {
            console.info('[FRAME] Antirobot not existed');
            (window as any).antirobot = {};
        } else {
            console.info('[FRAME] Antirobot already exists');
        }

        const createClient = () => {
            this.antirobotInstance = new (window as any).antirobot.Instance({
                sitekey: ConfigService.config.antirobot.sitekey // Veebilehele väljastatud sitekey
            });
        }

        if (typeof (window as any).antirobot.Instance === 'function') {
            console.info('[FRAME] antirobot.Instance already existed');
            createClient();
            return;
        } else {
            console.info('[FRAME] antirobot.Instance not existed. Wait');
            (window as any).antirobot.ready = () => {
                console.info('[FRAME] Antirobot is ready');
                createClient();
            };
        }

        this.createScriptTag();
    }

    private createScriptTag() {
        const existingScript = document.querySelector('script[src*="' + ConfigService.config.antirobot.jsUrl + '"]');
        if (existingScript) {
            console.log('Antirobot script already exists');
            return;
        }
        const script = document.createElement('script') as HTMLScriptElement;
        script.src = ConfigService.config.antirobot.jsUrl + '?ts=' + this.uid;
        const s = document.getElementsByTagName('script')[0];
        s.parentNode?.insertBefore(script, s);
    }

    public getKeyValue(key: string): Promise<AntirobotKeyResponse> {
        return new Promise((resolve, reject) => {
            this.getTrueValue(key).then((value) => {
                resolve(value);
            }).catch((reason) => {
                if (reason == 'BEHAVIOR_NOT_DETECTED') {
                    this.confirm().then(_ => {
                        this.getTrueValue(key).then((value1) => {
                            resolve(value1);
                        }).catch(reason1 => {
                            reject(reason1);
                        });
                    });
                } else {
                    reject(reason);
                }
            });
        });
    }

    private getTrueValue(key: string): Promise<AntirobotKeyResponse> {
        if (this.antirobotInstance === undefined) {
            console.error('[FRAME] Antirobot configuration wasn\'t fetched correctly');
            alert('Antirobot pole initialiseeritud');
            return Promise.reject('NOT_INITIALIZED');
        }

        return new Promise((resolve, reject) => {
            this.antirobotInstance.getKey(key).then((antirobotResponse: AntirobotKeyResponse) => {
                if (antirobotResponse.error) {
                    console.error('[FRAME] Failed to get antirobot key: ' + antirobotResponse.error);
                    return reject(antirobotResponse.error);
                }
                console.info('[FRAME] resolve getAntiRobotResponse ', antirobotResponse);
                resolve(antirobotResponse);
            });
        });
    }

    private confirm(): Promise<void> {
        return new Promise<void>((resolve) => {
            const div = document.createElement('div');
            const id = 'ohp-robot-confirm-modal' + new Date().getTime();

            div.innerHTML = `<veera-confirmation-modal 
                    id="${id}" 
                    text="Kinnitan, et ma ei ole robot." 
                    confirmation-button-text="Jah"
                    ></veera-confirmation-modal>`;

            document.body.append(div.firstChild);
            const robotModal = document.getElementById(id) as HTMLVeeraConfirmationModalElement;

            const removeModal = () => {
                console.log('Remove confirm modal');
                document.body.removeChild(robotModal);
                resolve();
            }

            robotModal.addEventListener('closeEvent', _ => {
                resolve();
            });
            robotModal.addEventListener('confirmEvent', _ => {
                removeModal();
            });
            robotModal.open();
        });
    }

    public destroy(): void {
        if (!this.antirobotInstance) {
            return;
        }

        this.antirobotInstance.destroy();
        this.antirobotInstance = null;
        const arScript = document.querySelector('script[src="' + ConfigService.config.antirobot.jsUrl + '"]');
        if (arScript) {
            arScript.parentNode?.removeChild(arScript);
        }
        console.info('[FRAME] Antirobot is destroyed');
    }
}

export const antirobot = Antirobot.getInstance();
