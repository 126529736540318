import {registerMicroApp, start} from '../spa/apis';
import {initGlobalState} from '../spa/globalState';
import {runAfterFirstMounted, setDefaultMountApp} from '../spa/effects';
import {Entry, MicroAppStateActions, RegistrableApp} from '../spa/interfaces';
import {addErrorHandler, AppError, getMountedApps} from 'single-spa';
import {
    FrameUserContext,
    GlobalState,
    LandingPageConfiguration,
    Site,
    UserRole,
    UserRoleOption
} from '../model/interfaces';
import {ConfigService} from '../ConfigService';
import {userContext} from '../model/UserContext';
import {sideNavigation} from './navigation/SideNavigation';
import {GlobalIframeApp} from '../apps/GlobalIframeApp';
import {header} from './navigation/Header';
import {notificationBar} from './navigation/NotificationBar';
import {MaintenanceApp} from '../apps/MaintenanceApp';
import {subAppLoader} from './SubAppLoader';
import {footer} from './navigation/Footer';
import {KeycloakLoginOptions, KeycloakLogoutOptions} from 'keycloak-js';
import {head} from './navigation/Head';
import {errorMessageBar} from './navigation/ErrorMessageBar';
import {getAccessibilityClasses} from './accessibility/utils';

export class FrameContent {

    private static instance: FrameContent;

    public static getInstance(): FrameContent {
        if (!FrameContent.instance) {
            FrameContent.instance = new FrameContent();
        }
        return FrameContent.instance;
    }

    private globalState: GlobalState;

    public readonly loader: any;
    private errorHandler: (error: AppError) => void;
    private actions: MicroAppStateActions;
    private landingPageConfiguration: LandingPageConfiguration;

    constructor() {
        subAppLoader.render({loading: true});
        this.loader = (loading: boolean) => {
            subAppLoader.render({loading});
        };
        this.setErrorHandler();
    }

    private setErrorHandler() {

        this.errorHandler = (error: AppError) => {
            this.loader(false);
            const site = this.getSiteByIdentifier(error.appOrParcelName);
            header.setLabel(site.label);
            header.setManual(site.manualSet ? site.id : null);
            head.setTitle(site.label);
            this.resetFrameState();
            errorMessageBar.appError((site.defaultErrorMessage ? site.defaultErrorMessage : 'Rakenduse laadimisel tekkis viga'), error.message, 'error');

            console.error('[FRAME] APP ERROR', error);
            if (error.appOrParcelName && site.defaultErrorMessage) {
                console.log('[FRAME] Site error messge found: ', site.defaultErrorMessage);
            }
        };
        addErrorHandler(this.errorHandler);
    }

    async init(): Promise<FrameContent> {

        this.landingPageConfiguration = await ConfigService.loadLandingPageConfiguration();
        notificationBar.frameNotification(this.landingPageConfiguration.notification?.message)
        sideNavigation.setSites(this.landingPageConfiguration.sites);
        header.setConfiguration(this.landingPageConfiguration);
        footer.setConfiguration(this.landingPageConfiguration);

        this.defineGlobalState();
        this.listenUserContextUpdates();
        this.registerMicroApps();

        this.actions.onGlobalStateChange((value: GlobalState, prev: GlobalState) => {
            console.log('[FRAME] [onGlobalStateChange - master]:', value, prev);
            header.updateFromGlobalState(value);
        });

        if (this.landingPageConfiguration.sites.length == 0) {
            console.error('[FRAME] No sites found');
            this.loader(false);
            return;
        }

        setDefaultMountApp('/');
        start({
            errorCallback: this.errorHandler
        });
        runAfterFirstMounted(() => {
            console.log('[FRAME] first app mounted');
        });

        return this;
    }

    private registerMicroApps(): void {
        const lifeCycles = this.getLifeCycles();

        this.landingPageConfiguration.sites.forEach((site, index) => {
            let app = {
                name: site.id,
                entry: site.src,
                container: '#ohp-subapp-viewport',
                loader: this.loader,
                activeRule: index > 0 ? site.path : (location: Location): boolean => {
                    return location.pathname.startsWith(site.path) || location.pathname == '/';
                },
                basePath: site.path,
                props: {
                    siteId: site.id,
                    entry: site.src,
                    globalState: this.globalState
                }
            } as RegistrableApp<any>

            if (site.inMaintenance || site.inIframe) {
                app.entry = {
                    html: '<div id="' + site.id + '"></div>',
                    scripts: [],
                    styles: []
                } as Entry;

                if (site.inMaintenance) {
                    MaintenanceApp.register(site);
                } else {
                    GlobalIframeApp.register(site);
                }
            }

            registerMicroApp(app, lifeCycles)
        });
    }

    private getSiteByIdentifier(identifier: string): Site {
        return this.landingPageConfiguration.sites.find(value => {
            return value.id == identifier || value.src == identifier;
        });
    }

    public navigateIndex() {
        const indexPath = this.landingPageConfiguration.sites[0].indexPath
        history.pushState(null, indexPath, indexPath);
    }

    private defineGlobalState() {
        this.globalState = {
            userContext: this.getFrameUserContext()
        } as GlobalState;

        this.actions = initGlobalState(this.globalState);
    }

    private listenUserContextUpdates() {
        userContext.onAuthRefreshUpdate.subscribe(() => {
            console.log('[FRAME] onAuthRefreshUpdate: ', userContext.getTokenParsed());
            this.globalState.userContext.token = userContext.getToken();
            this.globalState.userContext.tokenParsed = userContext.getTokenParsed();
            this.triggerGlobalStateUpdate();
        });
    }

    public getActiveSiteUrl() {
        const apps: string[] = getMountedApps();
        let siteUrl = window.location.origin;
        if (apps.length > 0) {
            const site = this.getSiteByIdentifier(apps[0]);
            siteUrl += site.indexPath;
        }
        console.log('[FRAME] ActiveSiteUrl', siteUrl);

        return siteUrl;
    }

    public selectRole(role: UserRoleOption) {
        this.globalState.userContext.role = role as UserRole;
        this.triggerGlobalStateUpdate();
    }

    private getFrameUserContext(): FrameUserContext {
        return {
            token: userContext.getToken(),
            tokenParsed: userContext.getTokenParsed(),
            role: null,
            setActiveRole: (role?: UserRole) => {
                this.globalState.userContext.role = role;
                header.updateFromGlobalState(this.globalState);
            },
            setRoles: (options?: UserRoleOption[]) => {
                header.setRoles(options);
            },
            login: (options?: KeycloakLoginOptions) => {
                userContext.login(options);
            },
            logout: (options?: KeycloakLogoutOptions) => {
                userContext.logout(options);
            }
        } as FrameUserContext;
    }

    public resetFrameState() {
        header.clearRoles();
        this.globalState.userContext = this.getFrameUserContext();
    }


    //sama mis trigger button tegi
    public triggerGlobalStateUpdate() {
        this.actions.setGlobalState(this.globalState);
    }

    private static updateBaseHref(base: string): void {
        if (!base.endsWith('/')) base += '/';
        let tag = document.getElementsByTagName('base')[0];
        tag.setAttribute('href', base);
    }

    private getLifeCycles() {
        return {
            beforeLoad: [
                (app: any) => {
                    console.log('[FRAME] [LifeCycle] before load %c%s', 'color: green;', app.name, app);
                    FrameContent.updateBaseHref(app.basePath);
                    return Promise.resolve();
                }
            ],
            beforeMount: [
                (app: any) => {
                    errorMessageBar.clearAppNotification();
                    this.resetFrameState();
                    console.log('[FRAME] [LifeCycle] before mount %c%s', 'color: green;', app.name, app);
                    FrameContent.updateBaseHref(app.basePath);
                    return Promise.resolve();
                }
            ],
            afterMount: [
                (app: any) => {
                    console.log('[FRAME] [LifeCycle] after mount %c%s', 'color: green;', app.name);
                    const site = this.getSiteByIdentifier(app.name);
                    header.setLabel(site.label);
                    header.setManual(site.manualSet ? site.id : null);
                    head.setTitle(site.label);

                    const appContainer = document.getElementById('app-container');
                    appContainer.scroll({top: 0});

                    return Promise.resolve();
                }
            ],
            beforeUnmount: [
                (app: any) => {
                    console.log('[FRAME] [LifeCycle] before unmount %c%s', 'color: green;', app.name);
                    this.updateBodyClasses();
                    return Promise.resolve();
                }
            ],
            afterUnmount: [
                (app: any) => {
                    console.log('[FRAME] [LifeCycle] after unmount %c%s', 'color: green;', app.name);
                    console.log(app);
                    const unmountedSite: Site = this.getSiteByIdentifier(app.name);
                    if (unmountedSite.spoilsBrowser) {
                        window.location.reload();
                        return; //no app anymore, all gone
                    }
                    sideNavigation.updateNavigation();

                    return Promise.resolve();
                }
            ]
        };
    }

    private updateBodyClasses() {
        const classes = getAccessibilityClasses();
        const invalidClasses: string[] = [];
        document.body.classList.forEach((value) => {
            if (classes.indexOf(value) === -1) {
                invalidClasses.push(value);
            }
        });
        invalidClasses.forEach((value) => {
            document.body.classList.remove(value);
        });
    }
}

export const frameContent = FrameContent.getInstance();
