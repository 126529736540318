import {LandingPageConfiguration} from '../../model/interfaces'
import {ConfigService} from '../../ConfigService';
import {accessibilityManager} from '../accessibility/AccessibilityManager';

class Footer {
    public veeraFooter: HTMLVeeraFooterElement

    private static instance: Footer

    public static getInstance(): Footer {
        if (!Footer.instance) {
            Footer.instance = new Footer()
        }

        return Footer.instance
    }

    public init(): Footer {
        this.veeraFooter = document.getElementById('ohp-footer') as HTMLVeeraFooterElement
        // this.veeraFooter.image = '/assets/image/eu.svg'
        this.veeraFooter.columns = []
        this.veeraFooter.accessibility = false;
        this.veeraFooter.addEventListener('showAccessibility', function () {
            accessibilityManager.editAccessibility();
        });
        return this
    }

    public setConfiguration(config: LandingPageConfiguration) {
        this.veeraFooter.columns = config.footerBlocks.map((footerBlock) => {
            return {
                title: footerBlock.name,
                body: footerBlock.content
            }
        });
        this.veeraFooter.accessibility = config.showAccessibility;
    }

    public setEnvironmentTag() {
        const envContainer = document.getElementById('ohp-environment-tag');
        if (ConfigService.config.environment) {
            envContainer.style.display = 'block';
            envContainer.textContent = ConfigService.config.environment;

/*            const ohpUserSwitchBtn = document.getElementById('ohp-user-switch-btn');
            ohpUserSwitchBtn.addEventListener('click', () => {
                this.switchUser();
            });*/
        }
    }

    public switchUser() {
        //Annab siin cors viga
        /*axios.post('http://localhost:8080/auth/realms/raam/protocol/openid-connect/token',
            qs.stringify({
                grant_type: 'client_credentials'
            }),
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                auth: {
                    username: 'kuttesusteemid',
                    password: 'f94a5a38-6a7b-43c2-8660-247f09fedf3d'
                }
            }
        ).then(response => {
            console.log(response);
        }).catch((err) => {
            console.log(err);
        });*/

        // Eelmise tulemuse peaks saama serverist
        /*axios.post('http://localhost:8080/auth/admin/realms/raam/users/76b70d42-e8d3-4024-9da6-85689e696ee3/impersonation',
            {},
            {
                withCredentials: true,
                headers: {
                    'Authorization': 'Bearer '
                }
            }
        ).then(response => {
            console.log(response);
        }).catch((err) => {
            console.log(err);
        });*/

    }
}

export const footer = Footer.getInstance()
