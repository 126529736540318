
import { getMountedApps, navigateToUrl } from 'single-spa';

const firstMountLogLabel = '[frame] first app mounted';

if (process.env.NODE_ENV === 'development') {
  console.time(firstMountLogLabel);
}

export function setDefaultMountApp(defaultAppLink: string) {
  window.addEventListener('single-spa:no-app-change', function listener() {
    const mountedApps = getMountedApps();
    if (!mountedApps.length) {
      navigateToUrl(defaultAppLink);
    }
    window.removeEventListener('single-spa:no-app-change', listener);
  });
}

export function runAfterFirstMounted(cb: () => void) {
  window.addEventListener('single-spa:first-mount', function listener() {
    if (process.env.NODE_ENV === 'development') {
      console.timeEnd(firstMountLogLabel);
    }
    cb();
    window.removeEventListener('single-spa:first-mount', listener);
  });
}
