import {concat, mergeWith} from 'lodash';
import type {LifeCycles, ParcelConfigObject} from 'single-spa';
import getAddOns from './addons';
import {getMicroAppStateActions} from './globalState';
import type {FrameworkConfiguration, FrameworkLifeCycles, LifeCycleFn, LoadableApp, ObjectType} from './interfaces';

import {
    Deferred,
    getContainer,
    getDefaultTplWrapper,
    performanceGetEntriesByName,
    performanceMark,
    performanceMeasure,
    toArray,
    validateExportLifecycle
} from './utils';
import {importEntry, ImportResult} from './html-import';

const rawAppendChild = HTMLElement.prototype.appendChild;
const rawRemoveChild = HTMLElement.prototype.removeChild;
type ElementRender = (
    props: { element: HTMLElement | null; container?: string | HTMLElement },
    phase: 'loading' | 'mounting' | 'mounted' | 'unmounted'
) => void;

let prevAppUnmountedDeferred: Deferred<void>;

export type ParcelConfigObjectGetter = (remountContainer?: string | HTMLElement) => ParcelConfigObject;


export async function loadApp<T extends ObjectType>(
    app: LoadableApp<T>,
    configuration: FrameworkConfiguration = {},
    lifeCycles?: FrameworkLifeCycles<T>
): Promise<ParcelConfigObjectGetter> {

    const {entry, name: appName} = app;
    const appInstanceId = `${appName}_${new Date().getTime()}`;

    const markName = `[frame] App ${appInstanceId} Loading`;
    if (process.env.NODE_ENV === 'development') {
        performanceMark(markName);
    }

    const {singular = false, ...importEntryOpts} = configuration;

    // get the entry html content and script executor
    let iImportResult: ImportResult = await importEntry(entry, importEntryOpts);

    // as single-spa load and bootstrap new app parallel with other apps unmounting
    // (see https://github.com/CanopyTax/single-spa/blob/master/src/navigation/reroute.js#L74)
    // we need wait to load the app until all apps are finishing unmount in singular mode
    if (await validateSingularMode(singular, app)) {
        await (prevAppUnmountedDeferred && prevAppUnmountedDeferred.promise);
    }

    const appContent = getDefaultTplWrapper(appInstanceId, appName, iImportResult.template);

    let initialAppWrapperElement: HTMLElement = createElement(appContent);

    const initialContainer = app.container;

    const render: ElementRender = getRender(appName);

    render({element: initialAppWrapperElement, container: initialContainer}, 'loading');

    let global = window;

    const {
        beforeUnmount = [],
        afterUnmount = [],
        afterMount = [],
        beforeMount = [],
        beforeLoad = []
    } = mergeWith(
        {},
        getAddOns(global, iImportResult.assetPublicPath),
        lifeCycles,
        (v1, v2) => concat(v1 ?? [], v2 ?? [])
    );

    await execHooksChain(beforeLoad, app, global);

    // get the lifecycle hooks from module exports
    const scriptExports: LifeCycles<any> = await iImportResult.execScripts(global);
    const {bootstrap, mount, unmount, update} = getLifecyclesFromExports(scriptExports, appName, global);

    const {
        onGlobalStateChange,
        setGlobalState,
        offGlobalStateChange
    }: Record<string, CallableFunction> = getMicroAppStateActions(appInstanceId);


    return (remountContainer = initialContainer) => {
        let appWrapperElement: HTMLElement | null = initialAppWrapperElement;
        const appWrapperGetter = getAppWrapperGetter(
            appName,
            appInstanceId,
            () => appWrapperElement
        );

        const parcelConfig: ParcelConfigObject = {
            name: appInstanceId,
            bootstrap,
            mount: [
                async () => {
                    if (process.env.NODE_ENV === 'development') {
                        const marks = performanceGetEntriesByName(markName, 'mark'); // mark length is zero means the app is remounting
                        if (marks && !marks.length) {
                            performanceMark(markName);
                        }
                    }
                },
                async () => {
                    if ((await validateSingularMode(singular, app)) && prevAppUnmountedDeferred) {
                        return prevAppUnmountedDeferred.promise;
                    }

                    return undefined;
                },

                async () => {
                    const useNewContainer = remountContainer !== initialContainer;
                    if (useNewContainer || !appWrapperElement) {
                        // element will be destroyed after unmounted, we need to recreate it if it not exist or we try to remount into a new container
                        appWrapperElement = createElement(appContent);
                    }
                    render({element: appWrapperElement, container: remountContainer}, 'mounting');
                },
                // exec the chain after rendering to keep the behavior with beforeLoad
                async () => execHooksChain(beforeMount, app, global),
                async (props) => mount({...props, container: appWrapperGetter(), setGlobalState, onGlobalStateChange}),
                // finish loading after app mounted
                async () => render({
                    element: appWrapperElement,
                    container: remountContainer
                }, 'mounted'),
                async () => execHooksChain(afterMount, app, global),
                // initialize the unmount defer after app mounted and resolve the defer after it unmounted
                async () => {
                    if (await validateSingularMode(singular, app)) {
                        prevAppUnmountedDeferred = new Deferred<void>();
                    }
                },
                async () => {
                    if (process.env.NODE_ENV === 'development') {
                        const measureName = `[frame] App ${appInstanceId} Loading Consuming`;
                        performanceMeasure(measureName, markName);
                    }
                }
            ],
            unmount: [
                async () => execHooksChain(beforeUnmount, app, global),
                async (props) => unmount({...props, container: appWrapperGetter()}),
                async () => execHooksChain(afterUnmount, app, global),
                async () => {
                    render({element: null, container: remountContainer}, 'unmounted');
                    offGlobalStateChange(appInstanceId);
                    // for gc
                    appWrapperElement = null;
                },
                async () => {
                    if ((await validateSingularMode(singular, app)) && prevAppUnmountedDeferred) {
                        prevAppUnmountedDeferred.resolve();
                    }
                }
            ]
        };

        if (typeof update === 'function') {
            parcelConfig.update = update;
        }

        return parcelConfig;
    };
}

/**
 * Get the render function
 * @param appName
 */
function getRender(appName: string): ElementRender {
    return ({element, container}, phase): void => {

        const containerElement = getContainer(container);

        // The container might have be removed after micro app unmounted.
        // Such as the micro app unmount lifecycle called by a react componentWillUnmount lifecycle, after micro app unmounted, the react component might also be removed
        if (phase !== 'unmounted') {
            const errorMsg = (() => {
                switch (phase) {
                    case 'loading':
                    case 'mounting':
                        return `[frame] Target container with ${container} not existed while ${appName} ${phase}!`;
                    case 'mounted':
                        return `[frame] Target container with ${container} not existed after ${appName} ${phase}!`;
                    default:
                        return `[frame] Target container with ${container} not existed while ${appName} rendering!`;
                }
            })();
            checkElementExist(containerElement, errorMsg);
        }

        if (containerElement && !containerElement.contains(element)) {
            // clear the container
            while (containerElement.firstChild) {
                rawRemoveChild.call(containerElement, containerElement.firstChild);
            }

            // append the element to container if it exist
            if (element) {
                rawAppendChild.call(containerElement, element);
            }
        }
    };
}

function getLifecyclesFromExports(scriptExports: LifeCycles<any>,
                                  appName: string,
                                  global: WindowProxy,
                                  globalLatestSetProp?: PropertyKey | null) {

    if (validateExportLifecycle(scriptExports)) {
        return scriptExports;
    }

    if (globalLatestSetProp) {
        const lifecycles = (<any>global)[globalLatestSetProp];
        if (validateExportLifecycle(lifecycles)) {
            return lifecycles;
        }
    }

    if (process.env.NODE_ENV === 'development') {
        console.warn(
            `[frame] lifecycle not found from ${appName} entry exports, fallback to get from window['${appName}']`
        );
    }

    // fallback to global variable who named with ${appName} while module exports not found
    const globalVariableExports = (global as any)[appName];

    if (validateExportLifecycle(globalVariableExports)) {
        return globalVariableExports;
    }

    throw new Error(`[frame] You need to export lifecycle functions in ${appName} entry`);
}

/** generate app wrapper dom getter */
function getAppWrapperGetter(
    appName: string,
    appInstanceId: string,
    elementGetter: () => HTMLElement | null
) {
    return () => {
        const element = elementGetter();
        checkElementExist(element, `[frame] Wrapper element for ${appName} with instance ${appInstanceId} is not existed!`);
        return element;
    };
}

function checkElementExist(element: Element | null | undefined, msg?: string) {
    if (!element) {
        if (msg) {
            throw new Error(msg);
        }

        throw new Error('[frame] element not existed!');
    }
}

function execHooksChain<T extends ObjectType>(
    hooks: LifeCycleFn<T>[] | LifeCycleFn<T>,
    app: LoadableApp<T>,
    global = window
): Promise<any> {
    hooks = toArray(hooks);
    if (hooks.length) {
        return hooks.reduce((chain, hook) => chain.then(() => hook(app, global)), Promise.resolve());
    }

    return Promise.resolve();
}

async function validateSingularMode<T extends ObjectType>(
    validate: FrameworkConfiguration['singular'],
    app: LoadableApp<T>
): Promise<boolean> {
    return typeof validate === 'function' ? validate(app) : !!validate;
}

function createElement(appContent: string): HTMLElement {
    const containerElement = document.createElement('div');
    containerElement.innerHTML = appContent;
    return containerElement.firstChild as HTMLElement;
}
