export const VEERA_ACCESSIBILITY = 'veeraAccessibility';

export interface Accessibility {
    contrast?: string;
    lineHeight?: string;
}

export function getAccessibilityClasses(): string[] {
    return [
        'high-contrast',
        'line-height-high'
    ];
}




