class ErrorMessageBar {

    private static instance: ErrorMessageBar;

    private appNotificationElement: HTMLVeeraNotificationPageElement;

    private constructor() {
        this.appNotificationElement = document.getElementById('ohp-error-message-bar') as HTMLVeeraNotificationPageElement;
        this.appNotificationElement.addEventListener("homeButtonClicked", () => {
            history.pushState(null, '/ohp', '/ohp')
        });
        return this;
    }

    public static getInstance(): ErrorMessageBar {
        if (!ErrorMessageBar.instance) {
            ErrorMessageBar.instance = new ErrorMessageBar();
        }
        return ErrorMessageBar.instance;
    }

    public init(): ErrorMessageBar {
        return this;
    }

    public clearAppNotification() {
        this.appNotificationElement.show = false;
    }

    public appError(title: string, message: string|undefined, type?: 'error'|'maintenance'|'404') {
        this.appNotificationElement.heading = title;
        this.appNotificationElement.textMessage = message;
        this.appNotificationElement.type = type ? type: 'error';
        this.appNotificationElement.show = true;
    }
}

export const errorMessageBar = ErrorMessageBar.getInstance();
