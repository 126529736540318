import {AntirobotKeyResponse, Site} from '../model/interfaces';
import {userContext} from '../model/UserContext';
import {Deferred} from '../spa/utils';
import {Accessibility, VEERA_ACCESSIBILITY} from '../components/accessibility/utils';
import {antirobot} from '../components/Antirobot';

const FRAME_TOKEN_UPDATE = 'frame-token-update'                 // Saada rakendusele sisselogimise token
const FRAME_TOKEN_ACCESSIBILITY = 'frame-content-accessibility' // Saada accessibility info
const FRAME_TOKEN_DO_LOGOUT = 'frame-content-do-logout'         // Ütle rakendusele, et see end välja logiks -> raam ootab vastuseks 'frame-content-logged-out'
const FRAME_TOKEN_LOGIN = 'frame-content-login'                 // rakenduses vajutati logi sisse
const FRAME_TOKEN_LOGOUT = 'frame-content-logout'               // rakenduses vajutati logi välja
const FRAME_CONTENT_READY = 'frame-content-ready'               // rakenduse leht laadis ennast ära
const FRAME_CONTENT_LOGGED_OUT = 'frame-content-logged-out'     // rakendus logis ennast sisemiselt välja / lõpetas sessiooni vmt

const FRAME_ANTIROBOT_INIT = 'frame-antirobot-init';                    // Rakendus soovib antirobotit kasutada
const FRAME_ANTIROBOT_KEY_REQUEST = 'frame-antirobot-key-request';      // Rakendus soovib antiroboti key väärtust
const FRAME_ANTIROBOT_KEY_RESPONSE = 'frame-antirobot-key-response';    // Saada key väärtus

class GlobalIframeAppImpl {

    private iframeEventTypes: string[] = [
        FRAME_TOKEN_LOGIN,
        FRAME_TOKEN_LOGOUT,
        FRAME_CONTENT_READY,
        FRAME_CONTENT_LOGGED_OUT,
        FRAME_ANTIROBOT_INIT,
        FRAME_ANTIROBOT_KEY_REQUEST
    ];

    private site: Site;
    private frameSourceWindow: any = undefined;
    private frameLogoutDefer: Deferred<void>;
    private isFrameContentReady = false;

    public messageListener = (event: any) => {

        const data = event.data;
        if (data == undefined || data.event == undefined || this.iframeEventTypes.indexOf(data.event) == -1) {
            return;
        }

        const entry: string = event.data.entry;

        //Ära üldse reageeri võõra iframe'i sisusle
        if (!entry.startsWith(this.site.src)) {
            console.log('[Iframe] invalid entry key: ' + this.site.src, data);
            return;
        }

        const eventName = event.data.event;

        if (eventName == FRAME_ANTIROBOT_INIT) {
            antirobot.init();
            return;
        }

        if (eventName == FRAME_ANTIROBOT_KEY_REQUEST) {
            antirobot.getKeyValue(data.key).then((arResponse) => {
                this.sendAntiRobotResponse(arResponse);
            }).catch((reason) => {
                this.sendAntiRobotResponse({error: reason} as AntirobotKeyResponse);
            });
            return;
        }

        if (eventName == FRAME_TOKEN_LOGIN) {
            userContext.login();
            return;
        }

        if (eventName == FRAME_TOKEN_LOGOUT) {
            userContext.logout();
            return;
        }

        if (eventName == FRAME_CONTENT_READY) {
            console.log('[Iframe] frame-content-ready: ' + entry, data);
            this.frameSourceWindow = event.source.window;

            if (!this.isFrameContentReady) {
                this.isFrameContentReady = true;
                this.updateFrameToken();
                this.updateAccessibility();
            }
        }

        if (eventName == FRAME_CONTENT_LOGGED_OUT) {
            console.log('[Iframe] content logged out ' + entry, data);
            this.frameLogOutDone();
        }
    };

    public frameAccessibilityListener = () => {
        this.updateAccessibility();
    }

    constructor(site: Site) {
        this.site = site;
    }

    private sendAntiRobotResponse(response: AntirobotKeyResponse) {
        if (!this.frameSourceWindow) {
            console.warn('[FRAME] Can not send antirobot response No frameSourceWindow found', this.site.src);
            return;
        }

        this.frameSourceWindow.postMessage({
            event: FRAME_ANTIROBOT_KEY_RESPONSE,
            antirobot: response
        }, this.getOrigin());
    }

    private getOrigin() {
        const pathArray = (this.site.src).split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        return protocol + '//' + host;
    }

    private updateAccessibility() {
        let accessibilityDetails: Accessibility = {contrast: null, lineHeight: null};
        const storageVeeraAccessibility = localStorage.getItem(VEERA_ACCESSIBILITY);
        if (storageVeeraAccessibility) {
            accessibilityDetails = JSON.parse(storageVeeraAccessibility) as Accessibility;
        }
        if (!this.frameSourceWindow) {
            console.error('Cant update accessibility. FrameSourceWindow status is unknown');
            return;
        }

        console.log('POST accessibility', FRAME_TOKEN_ACCESSIBILITY, accessibilityDetails);
        this.frameSourceWindow.postMessage({
            event: FRAME_TOKEN_ACCESSIBILITY,
            accessibility: accessibilityDetails
        }, this.getOrigin());
    }

    private updateFrameToken() {
        this.frameSourceWindow.postMessage({
            event: FRAME_TOKEN_UPDATE,
            token: userContext.getToken()
        }, this.getOrigin());
    }

    public doIframeLogout() {
        console.log('[Iframe] Do Iframe logout ');
        this.frameLogoutDefer = new Deferred<void>()
        if (!this.frameSourceWindow) {
            console.log('[Iframe] DoIframeLogout FrameSourceWindow Not Found');
            this.afterUnmount();
            return this.frameLogoutDefer.resolve();
        }
        setTimeout(() => {
            if (this.frameSourceWindow) {
                console.error('[Iframe] Iframes logout message not received');
                this.frameLogOutDone();
            }
        }, 5000);
        this.frameSourceWindow.postMessage({event: FRAME_TOKEN_DO_LOGOUT}, this.getOrigin());
        return this.frameLogoutDefer.promise;
    }

    private frameLogOutDone() {
        if (this.frameLogoutDefer) {
            this.afterUnmount();
            this.frameLogoutDefer.resolve();
        }
    }

    public afterUnmount() {
        window.removeEventListener('message', this.messageListener);
        window.removeEventListener(VEERA_ACCESSIBILITY, this.frameAccessibilityListener);
        this.isFrameContentReady = false;
        this.frameSourceWindow = undefined;
    }

    public getSiteEntry() {
        const framePath = window.location.origin + this.site.path;
        const href = window.location.href;
        const sufix = href.substring(framePath.length);
        if (sufix.length > 0) {
            console.log('[Iframe] Append site sufix: ', sufix);
        }

        return this.site.src + sufix;
    }
}

export class GlobalIframeApp {

    public static register(site: Site): void {
        let global = window as any;
        let frameAppImpl = new GlobalIframeAppImpl(site);
        global[site.id] = {
            bootstrap: () => {
                console.log('[Iframe] Bootstrap value: ');
                return Promise.resolve();
            },
            mount: (props: any) => {
                window.addEventListener('message', frameAppImpl.messageListener, true);
                window.addEventListener(VEERA_ACCESSIBILITY, frameAppImpl.frameAccessibilityListener, true);
                props.onGlobalStateChange((state: any) => {
                    console.log('[Iframe] mount state change', state);
                });
                let appFrame = document.createElement('iframe');
                appFrame.id = 'appInFrame';
                appFrame.style.height = window.outerHeight + 2 + 'px';
                appFrame.style.width = '100%';
                appFrame.style.border = 'none';
                appFrame.src = frameAppImpl.getSiteEntry();
                document.getElementById(props.siteId).appendChild(appFrame);
                return Promise.resolve();
            },
            unmount: (value: any) => {
                console.log('[Iframe] Do Unmount: ', value);
                if (site.iframeLogout) {
                    return frameAppImpl.doIframeLogout();
                } else {
                    frameAppImpl.afterUnmount();
                    return Promise.resolve();
                }
            }
        };
    }
}
