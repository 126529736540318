import {ConfigService} from '../ConfigService';
import {frameContent} from './FrameContent';
import {userContext} from '../model/UserContext';
import {sideNavigation} from './navigation/SideNavigation';
import {header} from './navigation/Header';
import {footer} from './navigation/Footer';
import {notificationBar} from './navigation/NotificationBar';
import {subAppLoader} from './SubAppLoader';
import {ErrorPage} from './ErrorPage';
import {errorMessageBar} from './navigation/ErrorMessageBar';
import {head} from './navigation/Head';
import {accessibilityManager} from './accessibility/AccessibilityManager';
import {antirobot} from './Antirobot';
import PullToRefresh from 'pulltorefreshjs';
import {VeeraSideNav} from "ohp-components/dist/types/components/veera-side-nav/veera-side-nav";

export class Application {

    public static init() {
        if (window.__POWERED_BY_FRAME__ == true) {
            //May happen if subapplication is proxied throw frame nginx
            // and returns some other error code
            // and returns frame index.html
            console.error('Application already initialized');
            return;
        }
        window.__POWERED_BY_FRAME__ = true;

        accessibilityManager.init();
        sideNavigation.init();
        header.init();
        footer.init();
        notificationBar.init();
        errorMessageBar.init();

        ConfigService.loadClientConfiguration().then(() => {
            console.log('[Frame] config inited');
            head.initMatomo(ConfigService.config.matomo);
            antirobot.init(); //Vastavalt vajadusele ei toiminud - ikka ei toiminud

            userContext.init().then(() => {
                console.log('[Frame] UserContext inited');
                header.initAuth();

                frameContent.init().then(() =>
                    console.log('[Frame] Loading is done')
                ).catch((reason: any) => {
                    this.showFrameErrorFallback(reason);
                });

            }).catch((reason: any) => {
                console.log('[Frame] UserContext init error', reason)
                this.showFrameErrorFallback(reason);
            });

            footer.setEnvironmentTag();
        }).catch((reason: any) => {
            console.log('[Frame] config init error', reason)
            this.showFrameErrorFallback(reason);
        });

        this.initPullToRefresh();
    }

    private static showFrameErrorFallback(error: any) {
        console.error('FRAME ERROR', error);
        subAppLoader.render(false);
        ErrorPage.render();
    }

    private static initPullToRefresh() {
        PullToRefresh.init({
            mainElement: 'body',
            instructionsPullToRefresh: 'Värskendamiseks tõmba alla',
            instructionsReleaseToRefresh: 'Värskendamiseks vabasta',
            instructionsRefreshing: 'Lehe värskendamine',
            onRefresh() {
                window.location.reload();
            },
            shouldPullToRefresh() {
                const scrollTopCondition = document.getElementById('app-container').scrollTop == 0;
                const sidenav = document.getElementById('ohp-nav-left') as HTMLElement & VeeraSideNav;
                let hasInvalidTarget = false;
                document.querySelectorAll(":hover").forEach(el => {
                    if(el.tagName == 'VEERA-DROPDOWN'){
                        hasInvalidTarget = true;
                    }
                });
                return scrollTopCondition && sidenav.navCollapsed && !hasInvalidTarget;
            }
        });
    }
}
