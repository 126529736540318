import {Accessibility, VEERA_ACCESSIBILITY} from './utils';

class AccessibilityManager {

    private static instance: AccessibilityManager

    public static getInstance(): AccessibilityManager {
        if (!AccessibilityManager.instance) {
            AccessibilityManager.instance = new AccessibilityManager()
        }
        return AccessibilityManager.instance
    }

    private accessibilityModal: HTMLVeeraAccessibilityModalElement;

    public init() {
        window.addEventListener(VEERA_ACCESSIBILITY, () => {
            AccessibilityManager.updateBodyAccessibilityClasses();
        }, false);
        AccessibilityManager.updateBodyAccessibilityClasses();
    }

    private static updateBodyAccessibilityClasses() {

        const accessibilityDetails = this.getAccessibility();
        if (accessibilityDetails.lineHeight) {
            document.body.classList.add(accessibilityDetails.lineHeight);
        } else {
            document.body.classList.remove('line-height-high');
        }

        if (accessibilityDetails.contrast) {
            document.body.classList.add(accessibilityDetails.contrast);
        } else {
            document.body.classList.remove('high-contrast');
        }
    }

    public editAccessibility() {
        if (!this.accessibilityModal) {
            this.initModal();
        }
        const accessibilityDetails = AccessibilityManager.getAccessibility()
        if (accessibilityDetails) {
            this.accessibilityModal.lineHeight = accessibilityDetails.lineHeight;
            this.accessibilityModal.contrast = accessibilityDetails.contrast;
        }
        this.accessibilityModal.open().then(() => {
            console.log('accessibilityModal opened');
        });
    }

    private initModal() {
        const div = document.createElement('div');
        div.innerHTML = '<veera-accessibility-modal id="ohp-accessibility-modal"></veera-accessibility-modal>';
        document.body.append(div.firstChild);
        this.accessibilityModal = document.getElementById('ohp-accessibility-modal') as HTMLVeeraAccessibilityModalElement;
        this.accessibilityModal.addEventListener('accessibilityChanged', (event: CustomEvent) => {
            const accessibilityDetails = event.detail as Accessibility;
            AccessibilityManager.setAccessibility(accessibilityDetails);
        });
    }

    public static setAccessibility(accessibility: Accessibility) {
        localStorage.setItem(VEERA_ACCESSIBILITY, JSON.stringify(accessibility));
        const event = new CustomEvent(VEERA_ACCESSIBILITY, {detail: accessibility});
        window.dispatchEvent(event);
    }

    public static getAccessibility(): Accessibility {
        const storageValue = localStorage.getItem(VEERA_ACCESSIBILITY);
        if (storageValue) {
            return JSON.parse(storageValue) as Accessibility;
        }
        return {} as Accessibility;
    }
}

export const accessibilityManager = AccessibilityManager.getInstance();
