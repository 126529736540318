import {Site} from '../../model/interfaces'
import {VeeraSideNav} from 'ohp-components/dist/types/components/veera-side-nav/veera-side-nav'
import {ConfigService} from '../../ConfigService'
import {header} from './Header';
import {Menu, MenuItem} from 'ohp-components/dist/types/components/veera-side-nav/menu-structure.model';

class SideNavigation {

    private static instance: SideNavigation;

    public static getInstance(): SideNavigation {
        if (!SideNavigation.instance) {
            SideNavigation.instance = new SideNavigation();
        }

        return SideNavigation.instance;
    }

    private navigation: HTMLElement & VeeraSideNav;

    private mobileMenu: MenuItem[] = [];
    private navigationList: MenuItem[] = [];

    public init(): SideNavigation {
        let veeraNav = document.getElementById('ohp-nav-left')
        this.navigation = veeraNav as HTMLElement & VeeraSideNav

        this.navigation.collapseString = 'Minimeeri menüü'
        this.navigation.expandMenu = 'Laiendan menüü'
        this.navigation.closeString = 'Sulgen menüü'
        this.navigation.expandedBackdrop = true
        this.navigation.navCollapsed = this.getNavCollapsed();

        this.navigation.addEventListener('routeChanged', (event: CustomEvent) => {
            console.log('[FRAME] routeChanged', event)

            if (event.type == 'routeChanged') {
                history.pushState(null, event.detail, event.detail)
                this.navigation.activeRoute = event.detail
            }
        });

        this.navigation.addEventListener('menuToggled', (event: CustomEvent) => {
            const navCollapsed = event.detail.navCollapsed;
            header.setSideNavOpen(!navCollapsed);
            this.storeNavCollapsed(navCollapsed);
        });

        return this
    }

    public setSites(sites: Site[]) {
        this.navigationList = sites.map((site) => {
            return {
                title: site.labelInMenu,
                returnRoute: site.indexPath,
                id: site.id,
                icon: site.iconSet ? ConfigService.config.frameApiUrl + '/v1/api/sites/' + site.id + '/icon' : undefined,
                visible: false
            } as MenuItem
        })

        this.updateNavigation()
    }

    setMobileMenu(mobileMenu?: MenuItem[]) {
        this.mobileMenu = mobileMenu;
        this.updateNavigation();
    }

    public updateNavigation() {
        let menus: Menu[] = [];
        if (this.mobileMenu && this.mobileMenu.length > 0) {
            menus.push({scrollable: false, childItems: this.mobileMenu});
        }
        menus.push({scrollable: true, childItems: this.navigationList});

        let pathname = window.location.pathname
        let item = this.navigationList.find((value) => pathname.startsWith(value.returnRoute));
        if (item) {
            this.navigation.activeRoute = item.returnRoute
        }

        this.navigation.menus = menus
    }

    public toggleNavigation() {
        Promise.resolve(this.navigation.isOpened()).then((isOpened) => {
            return Promise.resolve(isOpened ? this.navigation.close() : this.navigation.open());
        })
    }

    private NAV_COLLAPSED_KEY = 'ohp-nav-collapsed';

    private getNavCollapsed(): boolean {
        return localStorage.getItem(this.NAV_COLLAPSED_KEY) != undefined;
    }

    private storeNavCollapsed(collapsed: boolean): void {
        if (collapsed) {
            localStorage.setItem(this.NAV_COLLAPSED_KEY, 'collapsed');
        } else {
            localStorage.removeItem(this.NAV_COLLAPSED_KEY);
        }
    }
}

export const sideNavigation = SideNavigation.getInstance()
