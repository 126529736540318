import axios from 'axios';
import {FrameConfig, LandingPageConfiguration} from "./model/interfaces";
import {userContext} from "./model/UserContext";

export class ConfigService {

    public static config: FrameConfig;

    public static loadClientConfiguration(): Promise<any> {
        return axios.get('/assets/config.json').then((value: any) => {
            this.config = value.data;
            console.debug('[FRAME] Loaded config', this.config);
            return Promise.resolve();
        }).catch((reason) => {
            console.error('[FRAME] Config fetch failed', reason);
        });
    }

    public static async loadLandingPageConfiguration(): Promise<LandingPageConfiguration> {

        let headers = undefined
        if (userContext.getToken()) {
            headers = {
                Authorization: 'Bearer ' + userContext.getToken()
            };
        }

        let response = await axios.get(
            ConfigService.config.frameApiUrl + '/v1/api/landing_pages/origin',
            {
                params: {},
                headers: headers
            }
        );
        if (response.data) {
            return response.data as LandingPageConfiguration;
        }
        return Promise.reject('Invalid response object');
    }
}

