export class SubAppLoader {
    render({loading}: any) {
        const container = document.getElementById('ohp-subapp-loader');

        if (loading) {
            container.style.display = 'block';
        } else {
            container.style.display = 'none';
        }
    }
}

export const subAppLoader = new SubAppLoader();

