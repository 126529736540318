import {HeaderAuth} from 'ohp-components/dist/types/components/veera-header/header-auth.model'
import {userContext} from '../../model/UserContext'
import {HeaderLogo} from 'ohp-components/dist/types/components/veera-header/header-data.model'
import {frameContent} from '../FrameContent'
import {GlobalState, LandingPageConfiguration, UserRole, UserRoleOption} from '../../model/interfaces'
import {HeaderTopLink} from 'ohp-components/dist/types/components/veera-header/header-top-link.model'
import {sideNavigation} from './SideNavigation'
import {ConfigService} from '../../ConfigService';
import {MenuItem} from 'ohp-components/dist/types/components/veera-side-nav/menu-structure.model';
import axios from 'axios';

var qs = require('qs');

class Header {

    private static instance: Header

    public static getInstance(): Header {
        if (!Header.instance) {
            Header.instance = new Header()
        }
        return Header.instance
    }

    private manualLink: HeaderTopLink;
    MANUAL_ICON_PATH = '/assets/image/manual.svg';
    MANUAL_ICON_PATH_WHITE = '/assets/image/manual_white.svg';

    private helpLink: HeaderTopLink;
    HELP_ICON_PATH = '/assets/image/help.svg';
    HELP_ICON_PATH_WHITE = '/assets/image/help_white.svg';

    public veeraHeader: HTMLVeeraHeaderElement;

    public roles: UserRoleOption[] = [];
    public roleActive!: UserRole;

    private isDesktop = true;

    private authButtonTexts: any = {
        loginButtonLabel: 'Logi sisse',
        logoutButtonLabel: 'Logi välja'
        /*profileButtonLabel: 'Profiil'*/
    }

    public authData: HeaderAuth = Object.assign(
        {},
        {
            roleOptions: [],
            userName: undefined
        },
        this.authButtonTexts
    )

    public init(): Header {
        this.veeraHeader = document.getElementById('ohp-header') as HTMLVeeraHeaderElement;
        this.veeraHeader.logo = {src: '/assets/image/logo.svg', returnRoute: '/', alt: 'Ohutusportaal'} as HeaderLogo;
        this.veeraHeader.label = 'PÄÄSTEAMETI OHUTUSPORTAAL';
        this.veeraHeader.auth = this.authData

        this.veeraHeader.addEventListener('routeTriggered', (event: any) => {
            if (event.detail === 'impersonate') {
                this.impersonate();
                return;
            }

            if (event.detail === '/') {
                frameContent.navigateIndex();
            } else {
                history.pushState(null, event.detail, event.detail)
            }
        });

        this.veeraHeader.addEventListener('authTrigger', () => {
            userContext.login();
        });

        this.veeraHeader.addEventListener('menuTrigger', () => {
            sideNavigation.toggleNavigation()
        });

        this.veeraHeader.addEventListener('logoutTrigger', () => {
            userContext.logout()
        });

        this.veeraHeader.addEventListener('roleSelected', (event: any) => {
            const roleValue = event.detail;
            this.roles.forEach((value) => {
                if (value.value == roleValue) {
                    frameContent.selectRole(value);
                }
            });
        });

        this.veeraHeader.addEventListener('screenStateChanged', (event: CustomEvent) => {
            this.isDesktop = event.detail;
            this.updateSidenav();
        });

        return this
    }

    public setSideNavOpen(isSideNavOpen: boolean) {
        this.veeraHeader.isSideNavOpen = isSideNavOpen;
    }

    public initAuth(): void {
        this.updateAuthData();
    }

    public setLabel(label: string): void {
        this.veeraHeader.label = label
    }

    public setConfiguration(config: LandingPageConfiguration): void {
        this.veeraHeader.label = config.name
        this.setHelp(config.helpVisible);
    }

    public clearRoles(): void {
        this.roleActive = null;
        this.roles = [];
        this.updateAuthData();
    }

    public setRoles(roles: UserRoleOption[]): void {
        this.roles = roles;
        this.updateAuthData();
    }

    public updateFromGlobalState(state: GlobalState): void {
        this.roleActive = state.userContext.role;
        this.updateAuthData();
    }

    private updateAuthData(): void {
        if (userContext.isAuthenticated()) {
            const tokenParsed = userContext.getTokenParsed();
            this.veeraHeader.auth = Object.assign(
                {} as HeaderAuth,
                this.authButtonTexts,
                {
                    userRole: this.roleActive ? this.roleActive.label : undefined,
                    userName: tokenParsed.given_name + ' ' + tokenParsed.family_name,
                    roleOptions: this.roles,
                    isAuthenticated: true
                }
            );
        } else {
            this.veeraHeader.auth = Object.assign({} as HeaderAuth, this.authButtonTexts);
        }

        this.updateHeaderRightLinks();
    }

    public setHelp(visible: boolean) {
        if (visible) {
            this.helpLink = {
                title: 'Abi',
                icon: this.HELP_ICON_PATH,
                returnRoute: '/ohp/help'
            } as HeaderTopLink
        } else {
            delete this.helpLink;
        }

        this.updateHeaderRightLinks();
    }

    public setManual(id: string) {
        if (id) {
            this.manualLink = {
                title: 'Juhend',
                icon: this.MANUAL_ICON_PATH,
                url: ConfigService.config.frameApiUrl + '/v1/api/sites/' + id + '/manual',
                target: 'blank'
            } as HeaderTopLink
        } else {
            delete this.manualLink;
        }

        this.updateHeaderRightLinks();
    }

    private updateHeaderRightLinks() {
        const links = [];
        if (this.helpLink) {
            links.push(this.helpLink);
        }
        if (this.manualLink) {
            links.push(this.manualLink);
        }

        if (userContext.isAuthenticated()) {
            const tokenParsed = userContext.getTokenParsed();
            const realManagement = tokenParsed.resource_access['realm-management'];
            if (realManagement && realManagement.roles.includes('impersonation')
                && realManagement.roles.includes('query-users')) {
                links.push({
                    title: 'Vaheta kasutajat',
                    returnRoute: 'impersonate'
                } as HeaderTopLink);
            }
        }

        this.veeraHeader.rightLinks = links;
        this.updateSidenav();
    }

    public updateSidenav() {

        const items: MenuItem[] = [];

        if (!this.isDesktop) {
            if (this.helpLink) {
                items.push({
                    id: this.helpLink.title,
                    title: this.helpLink.title,
                    returnRoute: this.helpLink.returnRoute,
                    url: this.helpLink.url,
                    target: this.helpLink.target,
                    icon: this.HELP_ICON_PATH_WHITE
                } as MenuItem);
            }
            if (this.manualLink) {
                items.push({
                    id: this.manualLink.title,
                    title: this.manualLink.title,
                    returnRoute: this.manualLink.returnRoute,
                    url: this.manualLink.url,
                    target: this.manualLink.target,
                    icon: this.MANUAL_ICON_PATH_WHITE
                } as MenuItem);
            }
        }

        sideNavigation.setMobileMenu(items);
    }

    private impersonate() {
        const username = window.prompt("Kasutaja isikukood")
        if (!username) {
            return;
        }

        const usersUrl = ConfigService.config.keycloak.url + 'admin/realms/' + ConfigService.config.keycloak.realm + '/users?username=' + username;
        axios.get(usersUrl,
            {
                withCredentials: true,
                headers: {
                    Authorization: 'Bearer ' + userContext.getToken()
                }
            }
        ).then(response => {
            if (response?.data[0]) {
                this.doImpersonation(response?.data[0].id);
                return;
            }
            alert('Kasutajat ei leitud');
        }).catch((err) => {
            console.log(err);
            alert('Kasutaja pärimine ebaõnnestus');
        });
    }

    private doImpersonation(id: string) {
        const impersonateUrl = ConfigService.config.keycloak.url + 'admin/realms/' + ConfigService.config.keycloak.realm + '/users/' + id + '/impersonation';
        const token = userContext.getToken();

        let redirectUrl = ConfigService.config.keycloak.url;
        redirectUrl = redirectUrl.replace('/auth/', '/impersonate.html?')

        redirectUrl += 'url=' + encodeURIComponent(impersonateUrl);
        redirectUrl += '&token=' + encodeURIComponent(token);
        redirectUrl += '&redirectUrl=' + encodeURIComponent(window.location.href);
        console.log('Redirect: ', redirectUrl);
        window.location.href = redirectUrl;

        /*axios.post(impersonateUrl,
            {},
            {
                withCredentials: true,
                headers: {
                    Authorization: 'Bearer ' + token
                }
            }
        ).then(response => {
            console.log(response);
            window.location.reload();
        }).catch((err) => {
            console.log(err);
        });*/
    }
}

export const header = Header.getInstance()
