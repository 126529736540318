import type {RegisterApplicationConfig} from 'single-spa';
import {registerApplication, start as startSingleSpa} from 'single-spa';

import type {FrameworkConfiguration, FrameworkLifeCycles, ObjectType, RegistrableApp} from './interfaces';
import {LoadableApp} from './interfaces';
import {loadApp} from './loader';
import {Deferred, toArray} from './utils';

let microApps: RegistrableApp<Record<string, unknown>>[] = [];
const frameworkStartedDefer = new Deferred<void>();
let frameworkConfiguration: FrameworkConfiguration = {
    singular: true, //only one app can be active
    urlRerouteOnly: false
};

export function registerMicroApp<T extends ObjectType>(app: RegistrableApp<T>, lifeCycles: FrameworkLifeCycles<T>) {
    if (isAppRegistered(app)) {
        return;
    }

    const {name, loader, props, ...appConfig} = app;

    const singleSpaAppConfig: RegisterApplicationConfig = {
        name: app.name,
        app: async () => {
            loader(true);
            await frameworkStartedDefer.promise;
            const {mount, ...otherParcelConfigObjectConfigs} = (
                await loadApp(
                    {name, props, ...appConfig} as LoadableApp<T>,
                    frameworkConfiguration,
                    lifeCycles
                )
            )();

            return {
                mount: [
                    async () => loader(true),
                    ...toArray(mount),
                    async () => loader(false)
                ],
                ...otherParcelConfigObjectConfigs
            };
        },
        activeWhen: app.activeRule,
        customProps: app.props
    };

    microApps.push(app);
    //single-spa
    registerApplication(singleSpaAppConfig);
}

export function start(opts: FrameworkConfiguration) {
    frameworkConfiguration = {...opts};
    startSingleSpa({urlRerouteOnly: false}); // https://single-spa.js.org/docs/api/#start
    frameworkStartedDefer.resolve();
}

function isAppRegistered(app: RegistrableApp<any>): boolean {
    const registered = microApps.some((registeredApp) => registeredApp.name === app.name);
    if (registered) {
        console.warn('[FRAME] App already registered', app.name);
    }
    return registered;
}
